import { useEffect, useState } from 'react';

import { faExternalLink } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography, Box } from '@mui/material';
import { useTranslation } from 'next-i18next';

import { PolicyTopBox, PolicyTopContainer, ClosePolicyTopButton } from './style';

import config from 'src/config';
import { CookiePolicyState } from 'src/helpers/constants';
import { useAppData } from 'src/helpers/use';
import { getCookiePolicyAcceptedState, setAcceptCookiePolicy } from 'src/helpers/utils';

type CookiePolicyTopBarProps = {
  onStateChange?: (state: CookiePolicyState) => void;
};

const CookiePolicyTopBar = ({ onStateChange }: CookiePolicyTopBarProps) => {
  const { t } = useTranslation();
  const { appData } = useAppData();
  const [isShowCookiePolicyTopBar, setIsShowCookiePolicyTopBar] = useState(false);
  const [cookiePolicyState, setCookiePolicyState] = useState(CookiePolicyState.Accept);

  useEffect(() => {
    const currentCookiePolicyState = getCookiePolicyAcceptedState();
    if (!appData?.enableCookiePolicy) {
      setCookiePolicyState(currentCookiePolicyState);
      if (cookiePolicyState != CookiePolicyState.Accept) {
        setAcceptCookiePolicy(true);
        setIsShowCookiePolicyTopBar(true);
      } else {
        setIsShowCookiePolicyTopBar(false);
      }
    }
  }, [appData?.enableCookiePolicy]);

  useEffect(() => {
    if (onStateChange) onStateChange(cookiePolicyState);
  }, [cookiePolicyState, onStateChange]);

  const onClose = () => {
    setIsShowCookiePolicyTopBar(false);
  };

  return (
    <>
      {isShowCookiePolicyTopBar && !appData?.enableCookiePolicy && (
        <PolicyTopContainer>
          <PolicyTopBox>
            <Box sx={{ display: 'flex' }}>
              <Box sx={{ ml: 3, color: 'black', display: 'flex', alignItems: 'center' }}>
                <Typography className="desc" variant="subtitle2" sx={{ lineHeight: 1, ml: 0, fontWeight: 'normal' }}>
                  {t('cookie_policy_top.content1')} &nbsp;
                  <a
                    className="privacy-policy-link"
                    href={`${config.IDENTITY_SERVER_DOMAIN}/PrivacyPolicy`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t('cookie_policy.privacy_policy')} <FontAwesomeIcon icon={faExternalLink} />
                  </a>
                </Typography>
              </Box>
            </Box>

            <ClosePolicyTopButton variant="outlined" color="primary" onClick={onClose}>
              {t('cookie_policy_top.agree_all')}
            </ClosePolicyTopButton>
          </PolicyTopBox>
        </PolicyTopContainer>
      )}
    </>
  );
};

export default CookiePolicyTopBar;
